/**
 * AppDownload will render ios and android app download icons with links to respective stores
 *
 * @module views/components/AppDownload
 * @memberof -Common
 */
import './AppDownload.scss';

import React from 'react';

import PropTypes from 'prop-types';

import Image from '@ulta/core/components/Image/Image';
import Link_Huge from '@ulta/core/components/Link_Huge/Link_Huge';
import Text from '@ulta/core/components/Text/Text';
import { useDeviceInflection } from '@ulta/core/providers/InflectionProvider/InflectionProvider';

/**
 * Represents a AppDownload component
 *
 * @method
 * @param {AppDownloadProps} props - React properties passed from composition
 * @returns AppDownload
 */
export const AppDownload = function( props ){
  const { inflection } = useDeviceInflection();
  return (
    <div className='AppDownload'>
      <div className='AppDownload__content'>
        {
          props.iosAppImage && inflection.MOBILE &&

          <div className='AppDownload__appStoreIos'>
            <Link_Huge
              action={ props.iosAppAction }
            >
              <Image
                src={ props.iosAppImage.imageUrl }
                alt={ props.iosAppImageAltText }
                { ...( props.iosAppImage.metaData && { metaData: props.iosAppImage.metaData } ) }
              />
            </Link_Huge>
          </div>
        }
        {
          props.androidAppImage && inflection.MOBILE &&
            <div className='AppDownload__appStoreAndroid'>
              <Link_Huge
                action={ props.androidAppAction }
              >
                <Image
                  src={ props.androidAppImage.imageUrl }
                  alt={ props.androidAppImageAltText }
                  { ...( props.androidAppImage.metaData && { metaData: props.androidAppImage.metaData } ) }
                />
              </Link_Huge>
            </div>
        }

        {
          props.iconImage && props.appDownloadLabel && inflection.DESKTOP &&
          <div className='AppDownload__ultaIcon'>
            <div className='AppDownload__ultaIconImage'>
              <Image
                src={ props.iconImage.imageUrl }
                alt={ props.iconImageAltText }
                { ...( props.iconImage.metaData && { metaData: props.iconImage.metaData } ) }
              />
            </div>
            <div className='AppDownload__ultaIconText'>
              <Text
                htmlTag='p'
                textStyle='body-2'
                textAlign='left'
              >
                { props.appDownloadLabel }
              </Text>
            </div>
          </div>
        }
      </div>
    </div>
  );
};

/**
 * Property type definitions
 * @typedef AppDownloadProps
 * @type {object}
 * @property {object} iosAppImage - Sets the iosAppImage url
 * @property {string} iosAppImageAltText - Sets the iosAppImageAltText
 * @property {object} androidAppImage - Sets the androidAppImage url
 * @property {string} androidAppImageAltText - Sets the androidAppImageAltText
 * @property {object} iconImage - Sets the iconImage url
 * @property {string} iconImageAltText - Sets the iconImageAltText
 * @property {string} appDownloadLabel - Sets the appDownloadLabel
 * @property {object} iosAppAction - iOSApp download link
 * @property {object} androidAppAction - androidApp download link
 */
export const propTypes =  {
  iosAppImage: PropTypes.object,
  iosAppImageAltText: PropTypes.string,
  androidAppImage: PropTypes.object,
  androidAppImageAltText: PropTypes.string,
  iconImage: PropTypes.object,
  iconImageAltText: PropTypes.string,
  appDownloadLabel: PropTypes.string,
  iosAppAction: PropTypes.object,
  androidAppAction: PropTypes.object
};


AppDownload.propTypes = propTypes;

export default AppDownload;
